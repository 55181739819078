import * as React from 'react';

import { cn } from '@cardo/lib';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  ghost?: boolean;
  large?: boolean;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  children: React.ReactNode;
  name?: string;
  value?: string;
  onClick?: () => void;
  form?: string;
};

const primaryClassNames = 'bg-theme-purple text-white';
const secondaryClassNames = 'bg-[#0E44AC] text-white';
const dangerClassNames = 'bg-rose-600 text-white';
const ghostClassNames = '';
const defaultClassNames = 'border';
const disabledClassNames = 'pointer-events-none bg-gray-300';
const largeClassNames = 'py-2.5 px-6';
const defaultSizeClassNames = 'py-1.5 px-5';

export function Button({
  type = 'button',
  primary,
  secondary,
  danger,
  ghost,
  large,
  disabled,
  children,
  className,
  name,
  value,
  onClick,
  form,
}: ButtonProps) {
  const classNames = cn(
    'rounded-xl hover:shadow min-w-max',
    {
      [defaultSizeClassNames]: !large,
      [primaryClassNames]: !!primary,
      [secondaryClassNames]: !!secondary,
      [dangerClassNames]: !!danger,
      [ghostClassNames]: !!ghost,
      [defaultClassNames]: !primary && !secondary && !danger && !ghost,
      [disabledClassNames]: !!disabled,
      [largeClassNames]: !!large,
    },
    className
  );
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames}
      name={name}
      value={value}
      onClick={onClick || undefined}
      form={form}
    >
      {children}
    </button>
  );
}
